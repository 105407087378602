<template>
  <section class="edit-cart-container">
    <div class="edit-cart-section">
      <div
        class="order-items border-bottom-ccc"
        :class="order.order_type === 'QuoteOrder' ? 'quote-order-items' : ''"
      >
        <div class="display-none order-item-header">
          <h1 class="order-item-header-text">
            {{ $t('cart.yourCart') }}
          </h1>
        </div>
        <!-- <div
          v-if="order.order_type === 'QuoteOrder'"
          class="quote-order-items"
        > -->
        <QuoteOrderItems
          v-if="order.order_type === 'QuoteOrder'"
          :order="order"
        />
        <!-- </div> -->
        <div v-else>
          <OrderItem
            v-for="item in first3Items"
            :key="item.id"
            :cartversion="cart.cart_version"
            :item="item"
            :order="order"
            :showhotels="order.event_hotel_behavior"
          />
          <div
            v-if="display"
            class="extra-order-items"
          >
            <OrderItem
              v-for="item in remainingItems"
              :key="item.id"
              :cartversion="cart.cart_version"
              :item="item"
              :order="order"
              :showhotels="order.event_hotel_behavior"
            />
          </div>
          <HotelNeeded
            v-if="order.hotel_needed"
            :eventid="order.h4h_event_id"
          />
          <PriceChangeConfirmation
            v-if="order.price_change_item"
          />
          <p
            v-if="order.items.length > 3"
            class="brand-primary pointer padding-bottom-20"
            @click="toggleDisplay"
          >
            {{ toggleText }}
          </p>
        </div>
      </div>
      <div
        v-if="addOnsPossible"
      >
        <AddOns />
      </div>
    </div>

    <div class="edit-display-container open">
      <div class="display-none order-summary-header">
        <h3 class="expandable_header">
          {{ $t('checkout.orderSummary') }}
        </h3>
      </div>


      <div class="edit-display">
        <DiscountForm
          v-if="!depositOrder"
        />

        <Credits />
        <PointsSelection />

        <GPTDisplay />

        <div class="padding-top-10 padding-bottom-10">
          <SubAmountDisplay
            v-if="order.change_order"
            extraclass="change-order-subtotal"
            :header="$t('cart.subtotal')"
            :amount="order.change_order_subtotal"
          />

          <SubAmountDisplay
            v-else
            extraclass="subtotal"
            :header="$t('cart.subtotal')"
            :amount="order.subtotal_before_discounts"
          />

          <SubAmountDisplay
            v-if="order.discount > 0"
            extraclass="discount"
            :header="$t(&quot;formPackage.discount&quot;)"
            :amount="&quot;(&quot; + order.discount_decorated + &quot;)&quot;"
          />

          <SubAmountDisplay
            v-if="deposit_item"
            extraclass="deposit-item"
            :header="$t('cart.deposit')"
            :amount="deposit_item.price_decorated"
          />

          <SubAmountDisplay
            v-if="order.h4h_points > 0"
            extraclass="h4h-program"
            :header="cart.h4h_program"
            :amount="&quot;(&quot; + order.h4h_points_decorated + &quot;)&quot;"
          />

          <SubAmountDisplay
            v-if="refundableBooking"
            extraclass="protect-group-fee"
            :header="$t(&quot;checkout.protectGroup.refundableBooking&quot;)"
            :amount="protectGroupAmount"
          />

          <SubAmountDisplay
            v-if="creditUsed && order.customer_total_credit > 0"
            extraclass="cart-footer credit-amount negative-amount"
            :header="$t(&quot;cart.credit&quot;)"
            :amount="`(${order.credit_amount_decorated})`"
          />

          <SubAmountDisplay
            v-if="order.change_order"
            extraclass="change-order-already-paid"
            :header="$t('cart.previouslyPaid')"
            :amount="`(${order.change_order_already_paid})`"
          />

          <SubAmountDisplay
            v-if="order.tax > 0"
            extraclass="tax"
            :header="$t(&quot;cart.tax&quot;)"
            :amount="order.tax_decorated"
          />

          <SubAmountDisplay
            v-if="order.vat_tax > 0"
            extraclass="vat-tax"
            :header="$t(&quot;cart.vatTax&quot;)"
            :amount="order.vat_tax_decorated"
          />

          <SubAmountDisplay
            v-if="order.order_type == 'WebOrder'"
            extraclass="fee"
            :header="$t(&quot;cart.processingFee&quot;)"
            :amount="order.processing_fee_decorated"
          />

          <SubAmountDisplay
            v-if="!order.change_order && order.tax > 0"
            extraclass="fee-and-tax"
            :header="$t(&quot;cart.processingFee&quot;)"
            :amount="order.fee_and_tax_decorated"
          />

          <SubAmountDisplay
            v-if="order.total_roomcash > 0"
            extraclass="roomcash"
            :header="$t('cart.roomcash')"
            :amount="order.total_roomcash_decorated"
          />
        </div>


        <div class="total-charge">
          <AmountDisplay
            extraclass="total"
            :header="$t(&quot;cart.yourTotal&quot;)"
            :amount="displayAmount"
          />

          <CurrencyDisclaimer
            v-if="order.order_type != 'QuoteOrder'"
            :currency="order.charge_currency"
            :package-currency="order.default_package_currency"
            :chargeablecurrencies="order.chargeable_currencies"
          />
        </div>

        <div class="total-exchange">
          <AmountDisplay
            extraclass="total"
            :header="$t(&quot;cart.yourTotal&quot;)"
          />
          <CurrencyDisclaimer
            v-if="order.order_type != 'QuoteOrder'"
            :currency="order.currency"
            :package-currency="order.default_package_currency"
            :chargeablecurrencies="order.chargeable_currencies"
          />
        </div>

        <div
          v-if="order.order_type === 'QuoteOrder'"
          class="payment-terms-display"
        >
          <QuoteTermDisplay
            v-for="(term, i) in sorted_payment_terms()"
            :key="term.id"
            :currency="order.charge_currency"
            :index="i+1"
            :term="term"
            :payment-num="i+1"
          />
        </div>

        <div
          v-if="order.order_type === 'QuoteOrder'"
          class="total-due-today-wrapper"
        >
          <TotalDueDisplay
            extraclass="total-due-today"
            :header="$t('totalDueToday')"
            :amount="order.amount_due_today_decorated"
          />
          <CurrencyDisclaimer
            :currency="order.currency"
            :package-currency="order.default_package_currency"
            :chargeablecurrencies="order.chargeable_currencies"
          />
        </div>

        <button
          class="display-none send-to-checkout-btn btn btn-primary"
          @click="sendToCheckout"
        >
          {{ $t('h4h.checkoutStep') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import SubAmountDisplay from './sub_amount_display'
import AmountDisplay from './total_display'
import CurrencyDisclaimer from '../../../default/currency_convert/currency-disclaimer'
// import CurrencySelection from '../../cart/currency_selection'
import DiscountForm from '../../cart/discount_form'
import HotelNeeded from '../../cart/hotel_needed'
import PriceChangeConfirmation from '../../cart/price_change_confirmation'
import Credits from '../../cart/credit_selection'
import PointsSelection from './points_selection'
import GPTDisplay from '../../cart/gpt_display'
import OrderItem from '../../cart/order_items/order_item'
import AddOns from './add_ons'
import { mapMutations } from 'vuex'
import QuoteOrderItems from './quote_order_items'
import QuoteTermDisplay from '../../cart/payment_terms/quote_payment_terms_display'
import TotalDueDisplay from './total_due_display'
import moment from 'moment'

export default {
  components: {
    SubAmountDisplay,
    AmountDisplay,
    CurrencyDisclaimer,
    // CurrencySelection,
    PointsSelection,
    DiscountForm,
    HotelNeeded,
    Credits,
    PriceChangeConfirmation,
    GPTDisplay,
    OrderItem,
    AddOns,
    QuoteOrderItems,
    QuoteTermDisplay,
    TotalDueDisplay
  },

  props: {
    checkoutpage: {
      default: false,
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      display: false
    }
  },

  computed: {
    ...mapMutations('checkout', ['toggleCreditApplication']),
    depositOrder(){
      return this.order && this.deposit_product
    },
    addOnsPossible(){
      return this.order && this.order.add_ons_length > 0
    },
    protectGroupAmount(){
      if(this.dynamicProtectGroupEnabled){
        return this.$store.state.protect_group.decoratedPremium
      } else {
        return this.order.protect_group_cost_decorated
      }
    },
    dynamicProtectGroupEnabled(){
      return this.$store.state.protect_group.dynamicProtectGroupEnabled
    },
    toggleText(){
      return this.display ? 'View Less' : 'View All Order Items' // need to translate
    },
    first3Items(){
      return this.order.items.slice(0, 3)
    },
    remainingItems() {
      return this.order.items.slice(3)
    },
    deposit_product(){
      return this.order.items.find(item => item.sf_type == 'Deposit')
    },


    hasPaymentTerms() {
      return !(this.order.event_payment_term_options.slice(-1)[0] == 1)
    },
    refundableBooking(){
      return this.order.protect_group_amount_raw > 0
    },
    negativeAmount(){
      return this.order.credit_amount_decorated
    },
    customerTotalDecorated(){
      return this.order.customer_total_credit_decorated
    },
    cart() {
      return this.$store.state.order.cart
    },
    deposit_item(){
      return this.order.items.find( i=> i.deposit )
    },
    order(){
      return this.$store.state.order.order
    },
    orderLoading(){
      return this.$store.state.order.loading
    },
    creditUsed() {
      return this.order.credit_amount > 0
    },
    displayAmount(){
      // if(this.order.currencies.length > 1 &&  this.order.amount_after_credit.includes('$') ){
      return this.order.charge_currency + ' ' + this.order.amount_after_credit
      // } else {
      //   return this.order.amount_after_credit
      // }
    },
    paymentTermOptions(){
      return this.order.event_payment_term_options
    },
    disabledCart(){
      return this.order.disabled_cart
    },
  },
  methods: {
    toggleDisplay(){
      this.display = !this.display
    },
    sendToCheckout(){
      window.location.href = window.locale ? `/${window.locale}/cart/checkout` : '/cart/checkout'
    },
    dueDateMethod(due_date) {
      if (!due_date) {
        return new Date()
      } else {
        return moment(due_date).toDate()
      }
    },
    sorted_payment_terms() {
      return [...this.order.payment_terms].sort((a, b) => {
        const dateA = (this.dueDateMethod(a.due_date) && a.status !== 'passed') ? new Date() : this.dueDateMethod(a.due_date)
        const dateB = (this.dueDateMethod(b.due_date) && b.status !== 'passed') ? new Date() : this.dueDateMethod(b.due_date)

        return dateA - dateB
      })
    }
  }
}
</script>
